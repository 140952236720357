import React from 'react';

const Contact = () => {
    return (
        <div className="container mx-auto p-4 text-center mt-20">
            <h1 className="text-2xl sm:text-4xl font-semibold text-black mb-8">Mensajeria BISA</h1>
            <p className="text-lg text-gray-700">
                ¡Puedes contactarnos a través de cualquiera de nuestros siguientes medios de atención,
            </p>
            <p className="text-lg text-gray-700 mb-8">
                solo debes dar click sobre tu el icono de tu método preferido!
            </p>
            <div className="space-y-8 mt-28">
                <div className="flex items-center justify-center pr-7 space-x-2">
                    <a href="https://wa.me/50247499785" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                        <img src="/logos/whatsapp.png" alt="WhatsApp" className="w-16 h-16" />
                        <span className="text-black text-lg font-medium">4749-9785</span>
                    </a>
                </div>
                <div className="flex justify-center space-x-1">
                    <a href="http://instagram.com/bisamensajeria" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                        <img src="/logos/insta.png" alt="Instagram" className="w-16 h-16" />
                        <span className="text-black text-lg font-medium">bisamensajeria</span>
                    </a>
                </div>
                <div className="flex justify-center space-x-1">
                    <a href="https://facebook.com/mensajeriabisa" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                        <img src="/logos/face.png" alt="Facebook" className="w-16 h-16" />
                        <span className="text-black text-lg font-medium">mensajeriabisa</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Contact;
