import axios from 'axios';

const API_URL = 'https://gitlab-prod-ntsedlc2aq-uc.a.run.app/api/vehicles';

const saveVehicle = (licensePlate, pilotName, photoBlob, dateTime) => {
    const formData = new FormData();
    formData.append('licensePlate', licensePlate);
    formData.append('pilotName', pilotName);
    formData.append('photo', photoBlob, 'photo.jpg');
    formData.append('dateTime', dateTime);

    return axios.post(`${API_URL}/saveVehicle`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

const getVehiclesByLicensePlate = async (licensePlate) => {
    const response = await axios.get(API_URL, {
        params: {
            licensePlate: licensePlate.toLowerCase()
        }
    });
    return response;
};


const downloadImage = (imageId) => {
    return axios.get(`${API_URL}/download/${imageId}`, {
        responseType: 'blob'
    });
};

const deleteImage = (imageId) => {
    return axios.delete(`${API_URL}/${imageId}`);
};

const getAllVehicles = async () => {
    const response = await axios.get(`${API_URL}/all`);
    return response;
};

export default {
    saveVehicle,
    getVehiclesByLicensePlate,
    downloadImage,
    deleteImage,
    getAllVehicles
};
