import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const SuccessModal = ({ isSuccess, onClose }) => {
    if (!isSuccess) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                <div className="flex items-center justify-center mb-4">
                    <FaCheckCircle className="text-green-500 text-4xl mr-2" />
                    <h2 className="text-2xl">Se han cargado los datos correctamente</h2>
                </div>
                <button
                    onClick={onClose}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
                >
                    Cerrar
                </button>
            </div>
        </div>
    );
};

export default SuccessModal;
