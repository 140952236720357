import React, { useState, useEffect } from 'react';
import VehiclesServices from '../../services/VehiclesServices';
import { EyeIcon, TrashIcon } from '@heroicons/react/outline';

const VehiclesAdmin = () => {
    const [licensePlate, setLicensePlate] = useState('');
    const [images, setImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);

    useEffect(() => {
        const fetchAllVehicles = async () => {
            try {
                const response = await VehiclesServices.getAllVehicles();
                setImages(response.data);
            } catch (error) {
                console.error('Error fetching all vehicles:', error);
            }
        };

        fetchAllVehicles();
    }, []);

    const handleSearch = async () => {
        try {
            const response = await VehiclesServices.getVehiclesByLicensePlate(licensePlate);
            setImages(response.data);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const handleDeleteImage = async (imageId) => {
        try {
            await VehiclesServices.deleteImage(imageId);
            setImages(images.filter(img => img._id !== imageId));
            setShowModal(false); // Cerrar modal después de eliminar
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const openDeleteModal = (image) => {
        setImageToDelete(image);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const downloadImage = async (imageId) => {
        try {
            const response = await VehiclesServices.downloadImage(imageId);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'imagen.jpg');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    return (
        <div className="bg-gray-100 text-black container mx-auto p-4 font-copperplate">
            <h1 className="text-2xl sm:text-4xl text-yellow-500 mb-4 text-center">Administrador de Imágenes de Vehículos</h1>
            <div className="max-w-3xl mx-auto space-y-4">
                <div className="flex mb-4">
                    <input
                        type="text"
                        placeholder="Ingrese número de placa"
                        value={licensePlate}
                        onChange={(e) => setLicensePlate(e.target.value)}
                        className="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50"
                    />
                    <button onClick={handleSearch} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg ml-2">Buscar</button>
                </div>
                {images.length > 0 && (
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-800 text-white">
                        <tr>
                            <th className="w-1/4 py-2">Placa</th>
                            <th className="w-1/4 py-2">Fecha de Imagen</th>
                            <th className="w-1/4 py-2">Nombre del Piloto</th>
                            <th className="w-1/6 py-2">Ver</th>
                            <th className="w-1/6 py-2">Eliminar</th>
                        </tr>
                        </thead>
                        <tbody className="text-gray-700">
                        {images.map((image) => (
                            <tr key={image._id}>
                                <td className="text-center py-2">{image.licensePlate}</td>
                                <td className="text-center py-2">{new Date(image.dateTime).toLocaleString()}</td>
                                <td className="text-center py-2">{image.pilotName}</td>
                                <td className="text-center py-2">
                                    <EyeIcon
                                        className="h-6 w-6 text-blue-500 mx-auto cursor-pointer"
                                        onClick={() => downloadImage(image._id)}
                                    />
                                </td>
                                <td className="text-center py-2">
                                    <TrashIcon
                                        className="h-6 w-6 text-red-500 mx-auto cursor-pointer"
                                        onClick={() => openDeleteModal(image)}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
                {showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                            <h2 className="text-2xl mb-4">Confirmar Eliminación</h2>
                            <p>¿Está seguro que desea eliminar esta imagen?</p>
                            <div className="flex justify-center mt-4 space-x-4">
                                <button
                                    onClick={() => handleDeleteImage(imageToDelete._id)}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg"
                                >
                                    Confirmar
                                </button>
                                <button
                                    onClick={closeModal}
                                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VehiclesAdmin;
