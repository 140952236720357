import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000, // Cambiar imagen cada 2 segundos
    };

    return (
        <div className="carrousel-display">
            <Slider {...settings}>
                <div>
                    <img src="/images/imagen1.webp" alt="Imagen 1" className="w-full h-auto max-h-64 object-cover" />
                </div>
                <div>
                    <img src="/images/imagen.jpeg" alt="Imagen 2" className="w-full h-auto max-h-64 object-cover" />
                </div>
                <div>
                    <img src="/images/imagen1.webp" alt="Imagen 1" className="w-full h-auto max-h-64 object-cover" />
                </div>
                <div>
                    <img src="/images/imagen.jpeg" alt="Imagen 4" className="w-full h-auto max-h-64 object-cover" />
                </div>
            </Slider>
        </div>
    );
};

export default ImageCarousel;
