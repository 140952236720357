import axios from 'axios';

const API_URL = 'https://gitlab-prod-ntsedlc2aq-uc.a.run.app/api/orders';

const createOrder = (orderData) => {
    return axios.post(API_URL, orderData);
};

const getOrder = (orderNumber) => {
    return axios.get(`${API_URL}/${orderNumber}`);
};

const getAllOrders = () => {
    return axios.get(`${API_URL}/allOrders`);
};

const updateOrderStatus = (orderNumber, status) => {
    return axios.put(`${API_URL}/${orderNumber}`, { status });
};

const deleteOrder = (orderNumber) => {
    return axios.delete(`${API_URL}/${orderNumber}`);
};

export default {
    createOrder,
    getOrder,
    getAllOrders,
    updateOrderStatus,
    deleteOrder
};
