import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/main/Home';
import Contact from './pages/main/Contact';
import CreateRequest from './pages/orders/CreateRequest';
import Prices from './pages/main/Prices';
import Navbar from './components/shared/Navbar';
import Footer from './components/shared/Footer';
import Tracking from "./pages/orders/Tracking";
import AdminOrders from "./pages/orders/AdminOrders";
import Companies from "./pages/main/Companies";
import VehiclesHistory from "./pages/vehicles/VehiclesHistory";
import VehiclesAdmin from "./pages/vehicles/VehiclesAdmin";

const App = () => {
    useEffect(() => {
        const userStatus = localStorage.getItem('user');
        if (userStatus === null) {
            localStorage.setItem('user', 'false'); // Inicializar 'user' como 'false' si no está definido
        }
    }, []); // Ejecutar solo una vez al cargar la aplicación

    return (
        <Router>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Navbar />
                <Routes style={{ flex: 1 }}>
                        <Route path="/" element={<Home />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/delivery/createRequest" element={<CreateRequest />} />
                        <Route path="/prices" element={<Prices />} />
                        <Route path="/request/tracking" element={<Tracking />} />
                        <Route path="/admin/orders" element={<AdminOrders />} />
                        <Route path="/companies" element={<Companies />} />
                        <Route path="/vehicles/history" element={<VehiclesHistory />} />
                        <Route path="/vehicles/admin" element={<VehiclesAdmin />} />
                </Routes>
            </div>
            <Footer />
        </Router>
    );
};

export default App;
