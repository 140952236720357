import React from 'react';

const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                <h2 className="text-2xl mb-4">Cargando</h2>
                <div className="flex justify-center space-x-2">
                    <div className="h-4 w-4 bg-blue-500 rounded-full animate-bounce"></div>
                    <div className="h-4 w-4 bg-yellow-500 rounded-full animate-bounce delay-150"></div>
                    <div className="h-4 w-4 bg-blue-500 rounded-full animate-bounce delay-300"></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingModal;
