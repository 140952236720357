import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import orderService from '../../services/orderService';
import { ClipboardIcon, ChevronDownIcon, ChevronUpIcon, UserIcon, InboxIcon } from '@heroicons/react/outline';
import LoadingModal from '../../components/alerts/LoadingModal';

const CreateRequest = () => {
    const initialFormData = {
        senderName: '',
        senderPhone: '',
        pickupAddress: '',
        packageType: '',
        packageDescription: '',
        receiverName: '',
        receiverPhone: '',
        deliveryAddress: '',
        deliveryReferences: '',
        amount: '',
        paymentStatus: '',
        isShippingPaid: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);
    const [isError, setIsError] = useState(false);
    const [touched, setTouched] = useState({});
    const [orderNumber, setOrderNumber] = useState('');
    const [showInitialPopup, setShowInitialPopup] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [sectionsExpanded, setSectionsExpanded] = useState({
        remitente: true,
        destinatario: false,
        detalles: false
    });

    const validateSection = (sectionFields) => {
        return sectionFields.every(field => formData[field]);
    };

    const isSenderSectionValid = validateSection(['senderName', 'senderPhone', 'pickupAddress']);
    const isReceiverSectionValid = validateSection(['receiverName', 'receiverPhone', 'deliveryAddress']);
    const isDetailsSectionValid = validateSection(['packageType', 'paymentStatus', 'isShippingPaid']) &&
        (formData.packageType !== 'otros' || formData.packageDescription) &&
        (formData.paymentStatus !== 'Pendiente de pago' || formData.amount);

    useEffect(() => {
        if (!isModalOpen && !isError) {
            setFormData(initialFormData);
            setTouched({});
        }
    }, [isModalOpen, isError]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setTouched({ ...touched, [e.target.name]: true });
    };

    const validateForm = () => {
        return isSenderSectionValid && isReceiverSectionValid && isDetailsSectionValid;
    };

    const generateOrderNumber = () => {
        const randomNumber = Math.floor(Math.random() * 900) + 100;
        return randomNumber.toString();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            setIsFormValid(false);
            return;
        }

        setIsFormValid(true);
        const orderNumber = generateOrderNumber();
        const newOrder = { ...formData, orderNumber, status: 'Recolección recibida' };

        setIsLoading(true);

        try {
            await orderService.createOrder(newOrder);
            const templateParams = {
                ...formData,
                orderNumber,
                to_email: 'diegobiz0896@gmail.com,antoniobiz@hotmail.com'
            };
            await emailjs.send('service_65oleqw', 'template_3n1hnip', templateParams, 'CuXmCooSKe2MS3SAB');
            setIsError(false);
            setOrderNumber(orderNumber);
            setFormData(initialFormData);
            setTouched({});
        } catch (err) {
            setIsError(true);
            setIsFormValid(false);
        } finally {
            setIsLoading(false);
            setIsModalOpen(true);
        }
    };

    const toggleSection = (section) => {
        setSectionsExpanded({
            ...sectionsExpanded,
            [section]: !sectionsExpanded[section]
        });
    };

    const getBorderClass = (field) => {
        if (!touched[field] && isFormValid) return 'border-gray-300';
        return formData[field] ? 'border-green-500' : 'border-red-500';
    };

    const handleCopyOrderNumber = () => {
        navigator.clipboard.writeText(orderNumber)
            .then(() => {
                alert('Número de orden copiado al portapapeles');
            })
            .catch((err) => {
                console.error('Error al copiar el número de orden: ', err);
            });
    };

    return (
        <div className="bg-gray-100 text-black container mx-auto p-4 font-copperplate">
            <h1 className="text-2xl sm:text-4xl text-yellow-500 mb-4 text-center">Nueva Solicitud de Recolección</h1>
            {!isFormValid && <p className="text-red-500 text-center mb-4">Por favor, completa todos los campos obligatorios.</p>}
            <form onSubmit={handleSubmit} className="max-w-3xl mx-auto space-y-4">
                {/* Remitente */}
                <div className={`mb-4 `}>
                    <div className={`${isSenderSectionValid ? 'border border-green-500' : ''} bg-white rounded-lg p-3 mb-2`}>
                        <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleSection('remitente')}>
                            <h2 className="text-lg font-medium flex items-center">
                                <UserIcon className="h-5 w-5 text-gray-500 mr-2" />
                                Remitente (Persona que envía)
                            </h2>
                            {sectionsExpanded.remitente ? <ChevronUpIcon className="h-5 w-5 text-gray-500" /> : <ChevronDownIcon className="h-5 w-5 text-gray-500" />}
                        </div>
                    </div>
                    {sectionsExpanded.remitente && (
                        <div className="bg-white rounded-lg p-3 mt-2 space-y-4">
                            <div>
                                <label htmlFor="senderName" className="block mb-2 text-sm font-medium">Nombre de quien envía:</label>
                                <input
                                    type="text"
                                    name="senderName"
                                    value={formData.senderName}
                                    onChange={handleChange}
                                    className={`shadow-sm bg-white border ${getBorderClass('senderName')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="senderPhone" className="block mb-2 text-sm font-medium">Teléfono de quien envía:</label>
                                <input
                                    type="number"
                                    name="senderPhone"
                                    value={formData.senderPhone}
                                    onChange={handleChange}
                                    className={`shadow-sm bg-white border ${getBorderClass('senderPhone')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="pickupAddress" className="block mb-2 text-sm font-medium">Dirección de recolección:</label>
                                <input
                                    type="text"
                                    name="pickupAddress"
                                    value={formData.pickupAddress}
                                    onChange={handleChange}
                                    className={`shadow-sm bg-white border ${getBorderClass('pickupAddress')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    required
                                />
                            </div>
                        </div>
                    )}
                </div>

                {/* Destinatario */}
                <div className={`mb-4 `}>
                    <div className={`${isReceiverSectionValid ? 'border border-green-500' : ''} bg-white rounded-lg p-3 mb-2`}>
                        <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleSection('destinatario')}>
                            <h2 className="text-lg font-medium flex items-center">
                                <UserIcon className="h-5 w-5 text-gray-500 mr-2" />
                                Destinatario (Persona que recibe)
                            </h2>
                            {sectionsExpanded.destinatario ? <ChevronUpIcon className="h-5 w-5 text-gray-500" /> : <ChevronDownIcon className="h-5 w-5 text-gray-500" />}
                        </div>
                    </div>
                    {sectionsExpanded.destinatario && (
                        <div className="bg-white rounded-lg p-3 mt-2 space-y-4">
                            <div>
                                <label htmlFor="receiverName" className="block mb-2 text-sm font-medium">Nombre de quien recibe:</label>
                                <input
                                    type="text"
                                    name="receiverName"
                                    value={formData.receiverName}
                                    onChange={handleChange}
                                    className={`shadow-sm bg-white border ${getBorderClass('receiverName')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="receiverPhone" className="block mb-2 text-sm font-medium">Teléfono de quien recibe:</label>
                                <input
                                    type="number"
                                    name="receiverPhone"
                                    value={formData.receiverPhone}
                                    onChange={handleChange}
                                    className={`shadow-sm bg-white border ${getBorderClass('receiverPhone')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="deliveryAddress" className="block mb-2 text-sm font-medium">Dirección de entrega:</label>
                                <input
                                    type="text"
                                    name="deliveryAddress"
                                    value={formData.deliveryAddress}
                                    onChange={handleChange}
                                    className={`shadow-sm bg-white border ${getBorderClass('deliveryAddress')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    required
                                />
                            </div>
                        </div>
                    )}
                </div>

                {/* Detalles del paquete */}
                <div className={`mb-4 `}>
                    <div className={`${isDetailsSectionValid ? 'border border-green-500' : ''} bg-white rounded-lg p-3 mb-2`}>
                        <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleSection('detalles')}>
                            <h2 className="text-lg font-medium flex items-center">
                                <InboxIcon className="h-5 w-5 text-gray-500 mr-2" />
                                Detalles del paquete
                            </h2>
                            {sectionsExpanded.detalles ? <ChevronUpIcon className="h-5 w-5 text-gray-500" /> : <ChevronDownIcon className="h-5 w-5 text-gray-500" />}
                        </div>
                    </div>
                    {sectionsExpanded.detalles && (
                        <div className="bg-white rounded-lg p-3 mt-2 space-y-4">
                            <div>
                                <label htmlFor="packageType" className="block mb-2 text-sm font-medium">Descripción de paquete:</label>
                                <input
                                    type="text"
                                    name="packageType"
                                    value={formData.packageType}
                                    onChange={handleChange}
                                    className={`shadow-sm bg-white border ${getBorderClass('packageType')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    required
                                />
                            </div>
                            {formData.packageType === 'otros' && (
                                <div>
                                    <label htmlFor="packageDescription" className="block mb-2 text-sm font-medium">Descripción del paquete:</label>
                                    <textarea
                                        name="packageDescription"
                                        value={formData.packageDescription}
                                        onChange={handleChange}
                                        className={`shadow-sm bg-white border ${getBorderClass('packageDescription')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    />
                                </div>
                            )}
                            <div>
                                <label htmlFor="isShippingPaid" className="block mb-2 text-sm font-medium">¿El envío está pagado?</label>
                                <select
                                    name="isShippingPaid"
                                    value={formData.isShippingPaid}
                                    onChange={handleChange}
                                    className={`shadow-sm bg-white border ${getBorderClass('isShippingPaid')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    required
                                >
                                    <option value="">Seleccione una opción</option>
                                    <option value="Sí">Sí</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="paymentStatus" className="block mb-2 text-sm font-medium">¿El paquete está pagado?</label>
                                <select
                                    name="paymentStatus"
                                    value={formData.paymentStatus}
                                    onChange={handleChange}
                                    className={`shadow-sm bg-white border ${getBorderClass('paymentStatus')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    required
                                >
                                    <option value="">Seleccione una opción</option>
                                    <option value="Pagado">Sí</option>
                                    <option value="Pendiente de pago">No</option>
                                </select>
                            </div>
                            {formData.paymentStatus === 'Pendiente de pago' && (
                                <div>
                                    <label htmlFor="amount" className="block mb-2 text-sm font-medium">Monto: (si el envió no está pagado, poner el monto con la suma del paquete y el envío)</label>
                                    <input
                                        type="number"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                        className={`shadow-sm bg-white border ${getBorderClass('amount')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="flex justify-center">
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg" disabled={isLoading}>
                        {isLoading ? 'Cargando...' : 'Generar nueva recolección'}
                    </button>
                </div>
            </form>
            <LoadingModal isLoading={isLoading} />
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                        <svg
                            className={`mx-auto h-12 w-12 ${isError ? 'text-red-500' : 'text-green-500'}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isError ? "M6 18L18 6M6 6l12 12" : "M5 13l4 4L19 7"} />
                        </svg>
                        <h2 className="text-2xl mb-4">{isError ? 'Error al programar recolección' : `¡Recolección programada exitosamente! Número de orden: ${orderNumber}`}</h2>
                        {!isError && (
                            <div className="flex items-center justify-center space-x-2">
                                <span className="text-lg">{orderNumber}</span>
                                <ClipboardIcon className="h-6 w-6 text-blue-500 cursor-pointer" onClick={handleCopyOrderNumber} />
                            </div>
                        )}
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mt-4"
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            )}
            {showInitialPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                        <h2 className="text-2xl mb-4">No olvides copiar el número de tu orden al momento de generar tu recolección</h2>
                        <button
                            onClick={() => setShowInitialPopup(false)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mt-4"
                        >
                            Entendido
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateRequest;
