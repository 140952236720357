import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para controlar si el usuario está autenticado

    useEffect(() => {
        // Verificar el estado del localStorage al cargar el componente
        const userLoggedIn = localStorage.getItem('user') === 'true';
        setIsLoggedIn(userLoggedIn);
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
        setIsDropdownOpen(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        setIsLoggedIn(false); // Actualizar el estado local
        window.location.reload();
    };

    return (
        <nav className="bg-gray-900 border-gray-200 text-white dark:bg-gray-900 dark:border-gray-700 dark:text-white">
            <div className="max-w-screen-xl w-full flex flex-wrap justify-between mx-auto p-4">
                <Link to="/" className="flex space-x-3 rtl:space-x-reverse">
                    <img src="/logos/logoBISA.png" className="h-8 " alt="Flowbite Logo" />
                    <span className="text-white text-2xl font-semibold whitespace-nowrap">BISA</span>
                </Link>
                <button onClick={toggleMenu} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded={isOpen}>
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
                <div className={`w-full md:block md:w-auto ${isOpen ? '' : 'hidden'}`} id="navbar-dropdown">
                    <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-900 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-gray-900 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                        <li className="relative">
                            <button onClick={toggleDropdown} className="flex items-center justify-between w-full py-2 px-3 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent">
                                Servicios
                                <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                                </svg>
                            </button>
                            {/* Dropdown menu */}
                            <div className={`absolute md:absolute top-full left-0 mt-2 z-10 ${isDropdownOpen ? '' : 'hidden'} font-normal bg-gray-900 divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}>
                                <ul className="py-2 text-sm text-white dark:text-white">
                                    <li>
                                        <Link to="/prices" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-700 dark:hover:bg-gray-600">Precios</Link>
                                    </li>
                                    <li>
                                        <Link to="/delivery/CreateRequest" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-700 dark:hover:bg-gray-600">Programar recolección</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <Link to="/request/tracking" onClick={closeMenu} className="block py-2 px-3 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Rastrea tu paquete</Link>
                        </li>
                        <li>
                            <Link to="/contact" onClick={closeMenu} className="block py-2 px-3 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contacto</Link>
                        </li>
                        <li>
                            <Link to="/companies" onClick={closeMenu} className="block py-2 px-3 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Nuestras empresas</Link>
                        </li>

                        {/* Mostrar el botón de salir solo si el usuario está autenticado */}
                        {isLoggedIn && (
                            <li>
                                <button onClick={handleLogout} className="block py-2 px-3 text-white rounded hover:bg-gray-700 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Salir de test</button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
