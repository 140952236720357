import React, { useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import VehiclesServices from '../../services/VehiclesServices';
import LoadingModal from '../../components/alerts/LoadingModal';
import SuccessModal from '../../components/alerts/SuccessModal';

const VehiclesHistory = () => {
    const [licensePlate, setLicensePlate] = useState('');
    const [pilotName, setPilotName] = useState('');
    const [photo, setPhoto] = useState(null);
    const [cameraActive, setCameraActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const handleStartCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
            videoRef.current.play();
            setCameraActive(true);
        } catch (err) {
            console.error('Error al acceder a la cámara:', err);
            alert('Error al acceder a la cámara. Asegúrate de haber dado permisos en el navegador y que estás en un contexto seguro (HTTPS).');
        }
    };

    const handleTakePhoto = () => {
        const context = canvasRef.current.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        canvasRef.current.toBlob((blob) => {
            setPhoto(blob);

            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
            setCameraActive(false);
        }, 'image/jpeg', 0.7);
    };

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setPhoto(file);
        }
    };

    const handleDeletePhoto = () => {
        setPhoto(null);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const dateTime = new Date().toISOString();
        try {
            await VehiclesServices.saveVehicle(licensePlate.toLowerCase(), pilotName, photo, dateTime); // Guardar placa en minúscula
            setIsSuccess(true);
            setLicensePlate('');
            setPilotName('');
            setPhoto(null);
        } catch (err) {
            console.error('Error al guardar los datos:', err);
            alert('Error al guardar los datos');
        } finally {
            setIsLoading(false);
        }
    };

    const closeSuccessModal = () => {
        setIsSuccess(false);
    };

    const isFormValid = () => licensePlate && pilotName && photo;

    return (
        <div className="bg-gray-100 text-black container mx-auto p-4 font-copperplate mt-20">
            <LoadingModal isLoading={isLoading} />
            <SuccessModal isSuccess={isSuccess} onClose={closeSuccessModal} />
            <h1 className="text-2xl sm:text-4xl text-yellow-500 mb-8 text-center">Bitácora de Vehículos</h1>
            <div className="max-w-3xl mx-auto space-y-4">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="licensePlate">Ingresa tu placa</label>
                    <input
                        id="licensePlate"
                        type="text"
                        placeholder="Ingrese número de placa"
                        value={licensePlate}
                        onChange={(e) => setLicensePlate(e.target.value.toUpperCase())}
                        className="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50 max-w-xs mx-auto"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pilotName">Ingresa nombre de piloto</label>
                    <input
                        id="pilotName"
                        type="text"
                        placeholder="Ingrese nombre de piloto"
                        value={pilotName}
                        onChange={(e) => setPilotName(e.target.value)}
                        className="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50 max-w-xs mx-auto"
                    />
                </div>
                <div className="mb-8 text-center">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Agrega fotografía</label>
                    {isMobile ? (
                        <div className="relative inline-block">
                            <input
                                type="file"
                                accept="image/*"
                                capture="environment"
                                onChange={handlePhotoChange}
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                            />
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg">
                                Tomar fotografía
                            </button>
                        </div>
                    ) : (
                        <>
                            {!cameraActive && !photo ? (
                                <button
                                    onClick={handleStartCamera}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
                                >
                                    Iniciar Cámara
                                </button>
                            ) : (
                                <button
                                    onClick={handleTakePhoto}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
                                >
                                    Capturar Fotografía
                                </button>
                            )}
                        </>
                    )}
                </div>
                {!isMobile && (
                    <div className="mb-8">
                        <video ref={videoRef} className="mx-auto w-full max-w-md" style={{ display: cameraActive ? 'block' : 'none' }}></video>
                        <canvas ref={canvasRef} className="mx-auto w-full max-w-md" style={{ display: 'none' }}></canvas>
                    </div>
                )}
                {photo && (
                    <div className="mb-8 flex items-center justify-center">
                        <span className="text-black text-lg font-medium mr-4">Imagen.jpg</span>
                        <button onClick={handleDeletePhoto} className="text-red-500 text-lg font-bold">X</button>
                    </div>
                )}
                <div className="mb-8 text-center">
                    <button
                        onClick={handleSubmit}
                        className={`bg-blue-500 text-white font-bold py-2 px-4 rounded-lg ${!isFormValid() || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
                        disabled={!isFormValid() || isLoading}
                    >
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VehiclesHistory;
